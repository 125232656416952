     <template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="位置" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="0">首页</a-radio>
          <a-radio :value="1">首页底部</a-radio>
          <a-radio :value="2">cover letter</a-radio>
          <a-radio :value="3">行程单</a-radio>
          <a-radio :value="4">首页弹框</a-radio>
<!--          <a-radio :value="5">分享海报</a-radio>-->
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="图片地址" prop="imgUrl" >
        <file-upload type="image" :defaultList="form.imgUrlArr" :count="1"
                     @input="getImg($event, 'imgUrl')"></file-upload>
        <span v-if="form.type == 0" style="color: red">建议上传图片尺寸：686 x 320 px</span>
        <span v-if="form.type == 1" style="color: red">建议上传图片尺寸：686 x 420 px</span>
        <span v-if="form.type == 2 || form.type == 3" style="color: red">建议上传图片尺寸：686 x 179 px</span>
        <span v-if="form.type == 4" style="color: red">建议上传图片尺寸：548 x 640 px</span>
<!--        <span v-if="form.type == 5" style="color: red">建议上传图片尺寸：460 x 690 px</span>-->
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题"  />
      </a-form-model-item>
      <a-form-model-item label="链接类型" prop="linkType" >
        <a-radio-group v-model="form.linkType" @change="changeType">
          <a-radio :value="1">不跳转</a-radio>
          <a-radio :value="2">内链</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="内链地址" prop="link" v-if="form.linkType == 2">
        <a-input  v-model="form.link" placeholder="请输入外链地址" />
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="useFlag" >
        <a-radio-group v-model="form.useFlag">
          <a-radio :value="0">禁用</a-radio>
          <a-radio :value="1">启用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBanner, addBanner, updateBanner } from '@/api/banner/banner'
import {guid} from "@/utils/ruoyi";
import TagSelectOption from "@/components/TagSelect/TagSelectOption";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    TagSelectOption
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        type: null,
        imgUrl: null,
        imgUrlArr:[],
        imgUrlCount:1,
        title: null,
        linkType: 1,
        link: null,
        useFlag: 1,
        createTime: null,
        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        linkType: [
          { required: true, message: '链接类型不能为空', trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {

  },
  activated() {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeType(){
      this.form.link = null
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },


    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        imgUrl: null,
        imgUrlArr:[],
        imgUrlCount:1,
        title: null,
        linkType: 1,
        link: null,
        useFlag: 1,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.imgUrlArr = []
        let that = this
        if(this.form.imgUrl){
          this.form.imgUrlArr.push({
            uid: guid(8, 10),
            name: that.form.imgUrl,
            status: 'done',
            halfUrl: that.form.imgUrl,
            url: that.form.imgUrl,
            path: that.form.imgUrl
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
